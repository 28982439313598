/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, watch, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import useCryptoJs from '@core/utils/useCryptoJs'
import usePermissions from '@core/utils/usePermissions'
import { getInventory, getEditInventory } from '@api'
import useSelectOptions from '@/@core/utils/useSelectOptions'

export default function useProductCategoryList() {
  const dataListTable = ref([])
  const { configFacility, facilitiesOptions } = useSelectOptions()
  const { t } = useUtils()
  const { hasActionPermission, hasPermission } = usePermissions()
  const { userData } = useCryptoJs()

  // { text: t('facilities.facilities').toUpperCase(), value: 'facilities_ids', show: true },
  const tableColumns = computed(() => [
    { text: t('products.inventory_id').toUpperCase(), value: 'id', show: true, align: 'center' },
    { text: t('products.inventory_date').toUpperCase(), value: 'adjustment_date', show: true, align: 'center' },
    { text: t('products.inventory_creator').toUpperCase(), value: 'fullname', show: true, align: 'center' },
    { text: t('products.adjusted_products').toUpperCase(), value: 'adjusted_products', show: true, align: 'center' },
    { text: t('products.adjustment_cost').toUpperCase(), value: 'adjustment_cost', show: true, align: 'center' },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(49, 51, 52),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const tableEditColumns = computed(() => [
    { text: t('products.inventory_item').toUpperCase(), value: 'name', show: true },
    { text: t('products.inventory_system').toUpperCase(), value: 'prev_stock', show: true, align: 'center' },
    { text: t('products.inventory_stock').toUpperCase(), value: 'adj_stock', show: true, align: 'center' },
    { text: t('products.inventory_plus').toUpperCase(), value: 'diff', show: true, align: 'center' },
    { text: t('products.reason').toUpperCase(), value: 'reason', show: true, align: 'center' },
    { text: t('products.obs').toUpperCase(), value: 'observation', show: true, align: 'center' },
  ])
  const computedEditTableColumns = computed(() => tableEditColumns.value.filter(e => e.show))

  const totalDataListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['name'],
    sortDesc: [true],
  })
  const filters = ref(null)
  const searchQuery = ref('')
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)

  const fetchEditInventory = async facilityID => {
    let inventory = null
    if (facilityID) {
      inventory = await getEditInventory(facilityID)

      return inventory.data
    }

    return []
  }

  // fetch data
  const fetchInventory = async () => {
    let inventory = null
    if (configFacility.value !== null && configFacility.value !== undefined) {
      inventory = await getInventory(configFacility.value)

      const filterOption = {}
      filters.value = null
      if (searchQuery.value) filterOption.search = searchQuery.value
      filters.value = filterOption
      const filteredData = inventory.data.filter(
        item =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (item.adjustment_date && item.adjustment_date.toLowerCase().includes(searchQuery.value)) ||
          (item.fullname && item.fullname.toLowerCase().includes(searchQuery.value)),
      )

      dataListTable.value = filteredData
      totalDataListTable.value = filteredData.length
      loading.value = false
    }
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
    }
  }

  const getDataBySearch = async (time = 1000) => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchInventory()
    }, time)
  }

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await getDataBySearch(300)
    }
  })

  watch([configFacility], async () => {
    await getDataBySearch(300)
  })

  const resolveFacilitiesText = facilities => {
    const filter = facilitiesOptions.value.filter(e => facilities.includes(e.id))

    return filter.map(e => e.name).join(', ')
  }

  return {
    configFacility,
    dataListTable,
    tableColumns,
    computedTableColumns,
    computedEditTableColumns,
    totalDataListTable,
    userData,
    loading,
    options,
    filters,
    searchQuery,

    fetchInventory,
    fetchEditInventory,
    resolveFacilitiesText,
    hasPermission,
    setFilters,
    setOptions,
    getDataBySearch,
  }
}
